/* eslint-disable react/no-unescaped-entities */

import React, { useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import format from 'date-fns/format';

import css from './pdb.module.css';
import GET_FULL_PDB from '../../../queries/getFullPDB';
import Helper from '../../../icons/Helper';
import Chevron from '../../../icons/ChevronLeft';
import SetupPdbModal from '../../components/DailyBrief/SetupPdbModal/SetupPdbModal';
import EventCard from '../../components/Cards/EventCard/EventCard';

function Pdb() {
  const [isOpen, setIsOpen] = useState();

  const { data, loading, refetch } = useQuery(GET_FULL_PDB, {
    fetchPolicy: 'cache-first',
  });

  const pdbConfig = useMemo(() => data?.getUserPdb?.pdb, [data]);

  const closePdbSettings = () => {
    setIsOpen(false);
  };

  const onSavePdb = () => {
    refetch();
    closePdbSettings();
  };

  const openPdbSettings = () => {
    setIsOpen(true);
  };

  return (
    <div className={css.main}>
      <div className={css.sidenav}>
        <Link to="/" className={css.back}>
          <Chevron /> Back
        </Link>
      </div>

      {!loading && (
        <Content openPdbSettings={openPdbSettings} pdbConfig={pdbConfig} />
      )}

      <SetupPdbModal
        isOpen={isOpen}
        onClose={closePdbSettings}
        onSave={onSavePdb}
        config={pdbConfig}
      />
    </div>
  );
}

export default Pdb;

function Content({ openPdbSettings, pdbConfig }) {
  const date = useMemo(() => {
    return format(new Date(), 'MMMM d, yyyy');
  }, []);

  const { countries, industries, background, interests } = useMemo(() => {
    return {
      countries: pdbConfig.countries.map(({ name }) => name).join(', '),
      industries: pdbConfig.industries.map(({ name }) => name).join(', '),
      background: pdbConfig.background,
      interests: pdbConfig.interests?.join(', '),
    };
  }, [pdbConfig]);

  return (
    <div className={css.content}>
      <h1 className={css.title}>
        Personal Daily Brief <span> </span> {date}
      </h1>
      <div className={css.bottom}>
        <Helper />
        Based on your background, interests, countries, and industries.
        <div onClick={openPdbSettings}>Change Preferences.</div>
      </div>

      <p className={css.summary}>{pdbConfig.bulletin?.summary}</p>

      {pdbConfig.bulletin?.events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}

      <div className={css.divider} />
      <div className={css.bottom}>
        <Helper />
        Based on your background, interests, countries, and industries.
        <div onClick={openPdbSettings}>Change Preferences.</div>
      </div>

      <div className={css.setup}>
        <div>
          <span>Countries selected:</span> {countries}
        </div>
        <div>
          <span>Industries selected:</span> {industries}
        </div>
        <div>
          <span>Personal background:</span> {background}
        </div>
        <div>
          <span>Interests:</span> {interests}
        </div>
      </div>
    </div>
  );
}
