import * as React from 'react';

function PdfIcon(props) {
  return (
    <svg
      width={15}
      height={20}
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6325_25246)">
        <g filter="url(#filter0_ii_6325_25246)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.926 1.668c-.65 0-1.176.527-1.176 1.176v14.314c0 .65.527 1.177 1.176 1.177h11.147c.65 0 1.177-.527 1.177-1.177V2.844l-.001-.055v3.046l-4.167-4.167H1.926z"
            fill="#9AA4B2"
          />
        </g>
        <path
          opacity={0.5}
          d="M13.858 5.441h-3.385l3.776 3.776V5.832l-.391-.39z"
          fill="url(#paint0_linear_6325_25246)"
        />
        <path
          d="M11.26 5.832h2.99l-4.167-4.167v2.99c0 .65.527 1.177 1.177 1.177z"
          fill="#758190"
        />
        <path
          d="M2.52 16v-4h1.5c.308 0 .566.057.774.172.21.114.368.272.475.473.108.199.162.425.162.68 0 .256-.054.484-.162.683-.108.199-.268.356-.479.47-.21.114-.47.17-.779.17h-.994v-.595h.896c.18 0 .327-.032.442-.094a.592.592 0 00.254-.258.816.816 0 00.084-.377.804.804 0 00-.084-.375.57.57 0 00-.256-.252.938.938 0 00-.444-.091h-.664V16h-.724zm4.896 0H6.06v-4h1.383c.397 0 .738.08 1.024.24.286.16.506.388.66.686.153.298.23.655.23 1.07 0 .417-.077.775-.232 1.074-.154.3-.376.53-.666.69-.29.16-.637.24-1.043.24zm-.631-.627h.596c.278 0 .51-.05.697-.152a.975.975 0 00.42-.46c.094-.204.14-.459.14-.765s-.046-.56-.14-.762a.959.959 0 00-.416-.455 1.38 1.38 0 00-.68-.152h-.617v2.746zm3.26.627v-4h2.563v.607H10.77v1.086h1.662v.608H10.77V16h-.724z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_6325_25246"
          x={0.75}
          y={1.59444}
          width={13.5}
          height={16.8136}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={-0.147059} />
          <feGaussianBlur stdDeviation={0.0367647} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="shape" result="effect1_innerShadow_6325_25246" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={0.147059} />
          <feGaussianBlur stdDeviation={0.0367647} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend
            in2="effect1_innerShadow_6325_25246"
            result="effect2_innerShadow_6325_25246"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6325_25246"
          x1={10.4727}
          y1={5.3791}
          x2={15.0951}
          y2={8.37109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.2} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <clipPath id="clip0_6325_25246">
          <path fill="#fff" d="M0 0H15V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PdfIcon;
