const altnamesMap = {
  'United Kingdom': ['UK', 'Britain', 'Great Britain', 'England'],
  Germany: ['FRG'],
  Netherlands: ['Holland'],
  'Czech Republic': ['Czechia'],
  'New Zealand': ['NZ'],
  'Saudi Arabia': ['KSA'],
  'Trinidad And Tobago': ['T&T'],
  Macedonia: ['FYROM'],
  'Bosnia And Herzegovina': ['BiH'],
  India: ['Bharat'],
  Micronesia: ['FSM'],
  China: ['PRC'],
  'Timor Leste': ['East Timor'],
  'United States': ['USA', 'US', 'America'],
  Thailand: ['Siam'],
  'Brunei Darussalam': ['Brunei'],
  Iran: ['Persia'],
  Switzerland: ['Swiss Confederation'],
  'Hong Kong': ['HK'],
  'European Union': ['EU'],
  Turkey: ['Turkiye'],
  'United Arab Emirates': ['UAE'],
  Finland: ['Suomi'],
  'South Korea': ['ROK'],
  Taiwan: ['ROC'],
  "Cote D'Ivoire": ['Ivory Coast'],
  Jordan: ['Hashemite Kingdom'],
  Myanmar: ['Burma'],
  'Democratic Republic Of Congo': ['DRC', 'Congo-Kinshasa'],
  Eswatini: ['Swaziland'],
  'Papua New Guinea': ['PNG'],
  'North Korea': ['DPRK'],
  'Central African Republic': ['CAR'],
  'Cabo Verde': ['Cape Verde'],
};

export const includeCountryAltNames = (countries) => {
  return countries.map((country) => {
    const altNames = altnamesMap[country.name];

    if (altNames) {
      return { ...country, altNames };
    }

    return country;
  });
};
