import { gql } from '@apollo/client';

const query = gql`
  query searchEventsAndRegulations(
    $name: String
    $topics: [ID]
    $filterIndustries: [ID]
    $filterEntities: [ID]
    $filterCategories: FilterCategories
    $startDate: String
    $endDate: String
    $bookmarked: Boolean
    $connected: Boolean
    $notes: Boolean
    $compliance: Boolean
  ) {
    searchEventsAndRegulations(
      name: $name
      topics: $topics
      filterIndustries: $filterIndustries
      filterEntities: $filterEntities
      filterCategories: $filterCategories
      startDate: $startDate
      endDate: $endDate
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
    ) {
      events {
        skip
        limit
        data {
          id
          title
          date
          sources
          summary
          bullets
          sentiment
          magnitude
          language
          entities {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
          industries {
            id
            name
            type
            tooltip
          }
          countries {
            id
            name
          }
          landscapes {
            id
            name
          }
          notesCount
          quotes {
            id
            quote
            context
            speaker {
              id
              name
              shortestName
              image
              type
              topicsId
              inSubscription
              isConnected
              tooltip
            }
          }
        }
      }
      regulations {
        skip
        limit
        data {
          id
          legalName
          regulationType
          summarizedName
          issuer {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
          authorizedIssuer {
            id
            name
            type
            image
            shortestName
            tooltip
          }
          startDate
          endDate
          createdAt
          updatedAt
          summary
          bullets
          source
          attachment
          jurisdiction
          industries {
            id
            name
            type
            tooltip
          }
          notesCount
          isCompliant
        }
      }
      quotes {
        skip
        limit
        data {
          id
          quote
          context
          date
          createdAt
          updatedAt
          speaker {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
          industries {
            id
            name
          }
          countries {
            id
            name
          }
          event {
            id
            title
            sources
          }
        }
      }
      appointments {
        skip
        limit
        data {
          id
          title
          organization
          headline
          startDate
          entity {
            id
            name
            image
            type
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          organizationEntity {
            id
            name
            image
            type
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          createdAt
          updatedAt
          source
          event {
            id
            title
            sources
          }
        }
      }
    }
  }
`;

export default query;
