import { useQuery } from '@apollo/client';
import { useState } from 'react';
import QUERY_SEARCH_ENTITIES_AND_CONDITIONAL_SUGGESTIONS from '../../../queries/searchEntitiesAndSugestions';

function useSearchEntities() {
  const [entityResults, setEntityResults] = useState([]);
  const [aiEntitySuggestions, setAiEntitySuggestions] = useState([]);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(false);

  const [variables, setVariables] = useState({
    name: '',
    topics: [],
    filterIndustries: [],
    filterEntities: [],
    filterActorTypes: [],
    bookmarked: false,
    connected: false,
    compliance: false,
    notes: false,
    limit: 10,
    skip: 0,
  });

  const { loading } = useQuery(
    QUERY_SEARCH_ENTITIES_AND_CONDITIONAL_SUGGESTIONS,
    {
      variables: {
        ...variables,
        term: variables.name ?? null,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data) {
          setEntityResults((currentResults) => {
            if (variables.skip === 0) {
              return data.searchEntities.entities;
            }
            return [...currentResults, ...data.searchEntities.entities];
          });

          setAiEntitySuggestions(data.getSuggestions?.entities || []);
          setHasMoreToLoad(data.searchEntities.entities.length > 0);
        }
      },
    },
  );

  const fetchSearchEntities = ({
    search,
    type,
    countries,
    entities,
    industries,
    pageInfo,
    actorTypes,
  }) => {
    if (!['all', 'actors'].includes(type)) return;

    const vars = {
      name: search,
      topics: countries,
      filterEntities: entities,
      filterIndustries: industries,
      filterActorTypes: actorTypes.length === 8 ? [] : actorTypes,
      ...pageInfo,
    };

    const shouldRunAiSearch =
      vars.filterEntities.length === 0 &&
      vars.filterIndustries.length === 0 &&
      vars.topics.length === 0 &&
      search;

    setVariables({
      ...vars,
      shouldRunAiSearch: Boolean(shouldRunAiSearch),
      id: window.crypto.randomUUID(),
    });
  };

  return {
    entityResults,
    aiEntitySuggestions,
    hasMoreToLoad,
    fetchSearchEntities,
    setEntityResults,
    setAiEntitySuggestions,
    isEntityResultsLoading: loading,
  };
}

export default useSearchEntities;
