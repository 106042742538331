import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DossierType, UrlParam } from '../shared/dossier';

function useNavigation() {
  const history = useHistory();
  const location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  /** Push a single param */
  const pushParam = (paramName, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramName, value);
    history.push({ search: searchParams.toString() });
  };

  /** Delete a single param */
  const deleteParam = (paramName) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
    history.push({ search: searchParams.toString() });
  };

  const replaceParams = (searchParams) => {
    history.push({ search: searchParams.toString() });
  };

  const navigate = (path, searchParams, state = {}) => {
    history.push({ pathname: path, search: searchParams.toString(), state });
  };

  const deleteSecundaryParams = () => {
    params.delete(UrlParam.regulationId);
    params.delete(UrlParam.entityId);
    params.delete(UrlParam.eventId);
    params.delete(UrlParam.noteId);
    params.delete(UrlParam.appointmentId);
  };

  const toggleEntityDossier = (entityId) => {
    deleteSecundaryParams();
    params.set(UrlParam.entityId, entityId);
    params.set(DossierType.DossierType, DossierType.Entity);

    replaceParams(params);
  };

  const toggleRegulationDossier = (regulationId, noteId) => {
    deleteSecundaryParams();
    params.set(UrlParam.regulationId, regulationId);
    params.set(DossierType.DossierType, DossierType.Regulation);

    if (noteId) {
      params.set(UrlParam.noteId, noteId);
    }
    replaceParams(params);
  };

  const toggleEventDossier = (eventId, viewQuote = false) => {
    deleteSecundaryParams();
    params.set(UrlParam.eventId, eventId);
    params.set(DossierType.DossierType, DossierType.Event);
    params.set(UrlParam.viewQuote, viewQuote);

    replaceParams(params);
  };

  const toggleAppointmentDossier = (appointmentId) => {
    deleteSecundaryParams();
    params.set(UrlParam.appointmentId, appointmentId);
    params.set(DossierType.DossierType, DossierType.Appointment);
    replaceParams(params);
  };

  return {
    pushParam,
    deleteParam,
    replaceParams,
    navigate,
    toggleEntityDossier,
    toggleRegulationDossier,
    toggleEventDossier,
    toggleAppointmentDossier,
    params,
    location,
  };
}

export default useNavigation;
