import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import ForceGraph from 'react-force-graph-2d';
import { cloneDeep } from 'lodash';
import Controls from '../Controls/Controls';
import GraphFilters from '../GraphFilters/GraphFilters';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import getEntityGraph from '../../../../queries/entityGraph';
import getFeedGraph from '../../../../queries/feedGraph';
import getSearchGraph from '../../../../queries/searchGraph';
import { DOSSIER_WIDTH } from './utils/graph.constants';
import {
  drawLinks,
  zoomToFit,
  drawNodes,
  forcesConfigure,
  stopForces,
} from './utils/graph.utils';
import {
  addNodeDOMImage,
  addNodeNeighbors,
  setDataDepth,
} from './utils/node.utils';
import { useApolloClient } from '../../../../hooks/useApolloClient';
import { getGraphNodeTypes } from '../EntityFilter/utils';
import GraphLoader from '../GraphLoader/GraphLoader';
import { useIdentity } from '../../../../contexts/IdentityContext';
import GraphMenu from '../GraphMenu/GraphMenu';
import {
  filterTypes,
  getActorSubFiltersForGraph,
} from '../../../../v2/components/Filter';

const DEFAULT_GRAPH_STRENGTHS = [4, 5];

const fetchEntityGraphData = async ({ client, entityId, filters }) => {
  return client
    .query({
      query: getEntityGraph,
      variables: {
        entityId,
        filters,
      },
    })
    .then(({ data }) => data.getEntityGraph);
};

const fetchFeedGraphData = async ({ client, entityId, filters }) => {
  return client
    .query({ query: getFeedGraph, variables: { entityId, filters } })
    .then(({ data }) => data.getFeedGraph);
};

const fetchSearchGraphData = async ({
  client,
  name,
  topics,
  filterIndustries,
  filterEntities,
  semanticSearchIds,
  filters,
}) => {
  return client
    .query({
      query: getSearchGraph,
      variables: {
        name,
        topics,
        filterIndustries,
        filterEntities,
        semanticSearchIds,
        filters,
      },
    })
    .then(({ data }) => data.getSearchGraph);
};

const Graph = ({ entityId }) => {
  const router = useRouteInformation();
  const client = useApolloClient();
  const { user } = useIdentity();
  const [centerId, setCenterId] = useState(null);
  const [isGraphReady, setIsGraphReady] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({
    status: false,
    type: null,
    target: null,
  });

  const graphRef = useRef();
  const [triggerZoom, setTriggerZoom] = useState(true);
  const [graphFilterStrengths, setGraphFilterStrengths] = useState(
    DEFAULT_GRAPH_STRENGTHS,
  );
  const [graphFilterSecondaryStrengths, setGraphFilterSecondaryStrengths] =
    useState(DEFAULT_GRAPH_STRENGTHS);
  const [enableSecondaryFilter, setEnableSecondaryFilter] = useState(false);
  const [graphFilterIndustries, setGraphFilterIndustries] = useState([]);
  const [graphFilterCountries, setGraphFilterCountries] = useState([]);
  const [graphActiveFilter, setGraphActiveFilter] = useState(filterTypes.All);
  const [actorTypes, setActorTypes] = useState(getActorSubFiltersForGraph());

  const [extraFilter, setExtraFilter] = useState('');

  const resetGraphFilter = useCallback(() => {
    setGraphActiveFilter(filterTypes.All);
    setGraphFilterStrengths(DEFAULT_GRAPH_STRENGTHS);
    setGraphFilterIndustries([]);
  });

  const [graphData, setGraphData] = useState(null);

  const [highlightElements, setHighlightElements] = useState({
    nodes: [],
    links: [],
  });

  const isDossierOpen = !!router.query.get('dossierType');
  const searchViewData = router.query.get('data');
  let ticker = 0;
  const windowSize = useMemo(() => {
    const dossierWidth = isDossierOpen ? DOSSIER_WIDTH : 0;
    return {
      width: window.innerWidth - dossierWidth,
      height: window.innerHeight,
    };
  }, [window.innerWidth, window.innerHeight, isDossierOpen]);

  const resetMenu = () => {
    setIsMenuOpen({ status: false, type: null });
  };

  useEffect(() => {
    const fg = graphRef.current;
    if (!fg || !graphData) return;
    forcesConfigure(fg);
    setTriggerZoom(true);
    const { nodes } = graphData;
    const centerNode = nodes.find((node) => node.id === centerId);
    if (centerNode && centerNode.id) {
      centerNode.x = 0;
      centerNode.y = 0;
    }
  }, [graphRef, centerId]);

  const handleNodeHover = (node) => {
    resetMenu();
    if (node && !node.inSubscription) return;
    if (node && Array.isArray(node.neighbors)) {
      setHighlightElements({
        nodes: [node, ...node.neighbors],
        links: node.links,
      });
    } else {
      setHighlightElements({ nodes: [], links: [] });
    }
  };

  const handleLinkHover = (link) => {
    if (link) {
      setHighlightElements({
        nodes: [link.source, link.target],
        links: [link],
      });
    } else {
      setHighlightElements({ nodes: [], links: [] });
    }
  };

  const handleSetNodeFocus = ({ id, inSubscription }) => {
    resetMenu();
    setTriggerZoom(true);
    if (!inSubscription) return;
    setCenterId(id);
    router.toggleDossier({
      entityId: id,
      dossierEntityId: id,
      dossierType: 'entity',
    });
  };

  const handleNodeClick = ({ id, inSubscription, x, y, labels }) => {
    resetMenu();
    if (!inSubscription || labels.includes('User')) return;
    if (id === centerId || id !== router.activeEntityId) {
      router.toggleDossier({
        entityId: centerId,
        dossierEntityId: id,
        dossierType: 'entity',
      });
      graphRef.current.centerAt(x, y, 1000);
      graphRef.current.zoom(4, 1000);
    } else if (id === router.activeEntityId) {
      handleSetNodeFocus({ id, inSubscription });
    }
  };

  const handleLinkClick = (link) => {
    resetMenu();
    router.toggleDossier({
      entityId,
      source: link.source.id,
      target: link.target.id,
      dossierType: extraFilter === 'allConnections' ? 'allConnections' : 'link',
    });
  };

  /* eslint-disable-next-line no-unused-vars */
  const handleZoomEnd = ({ k }) => {
    if (!graphData || !triggerZoom) return;
    // const zoomNodeSize = clamp(NODE_RELSIZE * k, 150, 200);
    // setNodeSize(zoomNodeSize);
    const fg = graphRef.current;
    if (fg) forcesConfigure(fg);
  };

  const handleEngineTick = () => {
    if (!graphData) return;
    if (ticker < 50) {
      ticker++;
    } else if (triggerZoom) {
      zoomToFit(graphRef.current);
      setTriggerZoom(false);
    }
  };

  const handleEngineStop = () => {
    if (!graphData || !triggerZoom) return;
    stopForces(graphRef.current);
    zoomToFit(graphRef.current);
  };

  const recursiveHide = (data, id) => {
    if (!id) return data;
    let graphDataCopy = data;
    const selectedNode = graphDataCopy.nodes.find((node) => node.id === id);
    if (selectedNode) {
      selectedNode.expanded = false;
    }

    const deperExpansion = graphDataCopy.nodes.filter(
      (node) =>
        node.expanded &&
        node.depth > selectedNode?.depth &&
        node.expandedFrom === selectedNode?.id,
    );

    deperExpansion.forEach((node) => {
      graphDataCopy = recursiveHide(graphDataCopy, node.id);
    });

    graphDataCopy.links = graphDataCopy.links.filter(
      (link) => link.expandedFrom !== id,
    );

    graphDataCopy.nodes = graphDataCopy.nodes.filter(
      (node) => node.expandedFrom !== id,
    );

    return graphDataCopy;
  };

  const hideEntity = ({ id }) => {
    const processedGraph = recursiveHide(graphData, id);
    const selectedNode = processedGraph.nodes.find((node) => node.id === id);

    // hide node and all nodes that have exclusive links to it
    processedGraph.nodes = processedGraph.nodes.filter((node) => {
      if (node.id === id) return false;
      const nodeLinks = graphData.links.filter(
        (link) => link.source.id === node.id || link.target.id === node.id,
      );

      if (!nodeLinks.length) return true;

      const notExclusiveLink = nodeLinks.some(
        (link) =>
          (link.source.id !== id && link.target.id === node.id) ||
          (link.source.id === node.id && link.target.id !== id),
      );

      return notExclusiveLink;
    });

    // remove expanded flag from BaseExpanded Entity
    const baseNode = processedGraph.nodes.find(
      (node) => node.id === selectedNode.expandedFrom,
    );

    const baseNodeExpandedLinks = processedGraph.nodes.filter(
      (node) =>
        node.expandedFrom === baseNode?.id &&
        node.depth > baseNode?.depth &&
        node.id !== selectedNode.id,
    );

    if (baseNodeExpandedLinks.length === 0) {
      if (baseNode) baseNode.expanded = false;
    }

    processedGraph.links = processedGraph.links.filter(
      (link) => link.source.id !== id && link.target.id !== id,
    );

    setGraphData(processedGraph);
    resetMenu();
  };

  const hideRelationships = ({ id }) => {
    const processedGraph = recursiveHide(graphData, id);

    setGraphData(processedGraph);
    resetMenu();
  };

  const expandNode = ({ id }) => {
    setHighlightElements({
      nodes: [],
      links: [],
    });
    setIsGraphReady(false);
    fetchEntityGraphData({
      client,
      entityId: id,
      graphFilterSecondaryStrengths,
      filters: {
        nodeTypes: getGraphNodeTypes(graphActiveFilter, actorTypes),
        strengths: graphFilterStrengths,
        secondaryStrengths: graphFilterSecondaryStrengths,
        industries: graphFilterIndustries.map((industry) => industry.id),
      },
    }).then((data) => {
      const graphNodeIds = graphData.nodes.map((node) => node.id);
      const expandedNode = graphData.nodes.find((node) => node.id === id);
      expandedNode.expanded = true;

      let fullData = addNodeDOMImage(data);
      fullData = addNodeNeighbors(fullData);

      const filteredData = {
        nodes: fullData.nodes.filter((node) => !graphNodeIds.includes(node.id)),
        links: fullData.links.filter(
          (link) =>
            !graphData.links.some((existingLink) => {
              return (
                existingLink.source.id === link.source &&
                existingLink.target.id === link.target &&
                existingLink.type === link.type &&
                existingLink.strength === link.strength
              );
            }),
        ),
      };

      fullData = setDataDepth(filteredData, id, expandedNode?.depth + 1);
      expandedNode.vx *= 50;
      expandedNode.vy *= 50;

      graphData.nodes = [
        ...graphData.nodes.map((node) => {
          node.vx = 0;
          node.vy = 0;
          return node;
        }),
        ...fullData.nodes
          .filter((node) => graphNodeIds.includes(node.id) === false)
          .map((node) => {
            node.vx = expandedNode.x;
            node.vy = expandedNode.y;
            return node;
          }),
      ];
      graphData.links = [...graphData.links, ...fullData.links];

      let clonedData = cloneDeep(graphData);

      const nodeDepth = {};
      const linkDepth = {};

      clonedData.nodes.forEach((node) => {
        if (nodeDepth[node.depth]) {
          nodeDepth[node.depth].push(node);
        } else {
          nodeDepth[node.depth] = [node];
        }
      });

      clonedData.links.forEach((link) => {
        if (linkDepth[link.depth]) {
          linkDepth[link.depth].push(link);
        } else {
          linkDepth[link.depth] = [link];
        }
      });

      clonedData = addNodeNeighbors(clonedData);

      setGraphData(clonedData);
      setTriggerZoom(true);
      setIsGraphReady(true);
    });
    resetMenu();
  };

  const drawCanvasLinks = (link, context, scale) => {
    drawLinks({
      centerNodeId: centerId,
      link,
      context,
      scale,
      highlightLinks: highlightElements.links,
    });
  };

  const drawCanvasNodes = (node, context, scale) => {
    drawNodes({
      node,
      context,
      scale,
      highlightNodes: highlightElements.nodes,
    });
  };

  const getNodeType = (nodeId) => {
    if (!graphData) return '';
    const { nodes = [] } = graphData;
    const centerNode = nodes.find((node) => node.id === nodeId);
    return centerNode ? centerNode.labels[0] : '';
  };

  const openMenu = (e, type) => {
    setIsMenuOpen({ status: true, type, target: e });

    const menu = document.getElementById('GraphMenu');

    if (type === 'BG') {
      menu.style.top = `${e.y}px`;
      menu.style.left = `${e.x - 50}px`;
      return;
    }

    if (type === 'LINK') {
      const { x, y } = graphRef.current.graph2ScreenCoords(
        (e.target.x + e.source.x) / 2,
        (e.target.y + e.source.y) / 2,
      );

      menu.style.top = `${y}px`;
      menu.style.left = `${x}px`;
      return;
    }

    const { x, y } = graphRef.current.graph2ScreenCoords(e.x, e.y);

    menu.style.top = `${y}px`;
    menu.style.left = `${x}px`;
  };

  const applyFilters = () => {
    setIsGraphReady(false);
    setTriggerZoom(true);
    setHighlightElements({
      nodes: [],
      links: [],
    });
    if (entityId) {
      setEnableSecondaryFilter(true);
      fetchEntityGraphData({
        client,
        entityId: centerId || entityId,
        graphFilterSecondaryStrengths,
        filters: {
          nodeTypes: getGraphNodeTypes(graphActiveFilter, actorTypes),
          strengths: graphFilterStrengths,
          secondaryStrengths: graphFilterSecondaryStrengths,
          industries: graphFilterIndustries.map((industry) => industry.id),
          countries: graphFilterCountries.map((country) => country.id),
          connections: extraFilter,
        },
      }).then((data) => {
        let fullData = addNodeDOMImage(data);
        fullData = addNodeNeighbors(fullData);
        fullData = setDataDepth(fullData);
        setGraphData(fullData);
        setIsGraphReady(true);
      });
    } else if (searchViewData) {
      setEnableSecondaryFilter(false);
      fetchSearchGraphData({
        client,
        filters: {
          nodeTypes: getGraphNodeTypes(graphActiveFilter, actorTypes),
          strengths: graphFilterStrengths,
          industries: graphFilterIndustries.map((industry) => industry.id),
          countries: graphFilterCountries.map((country) => country.id),
          connections: extraFilter,
        },
        ...JSON.parse(searchViewData),
      }).then((data) => {
        let fullData = addNodeDOMImage(data);
        fullData = addNodeNeighbors(fullData);
        fullData = setDataDepth(fullData);
        setGraphData(fullData);
        setIsGraphReady(true);
      });
    } else {
      setEnableSecondaryFilter(false);
      fetchFeedGraphData({
        client,
        filters: {
          nodeTypes: getGraphNodeTypes(graphActiveFilter, actorTypes),
          strengths: graphFilterStrengths,
          industries: graphFilterIndustries.map((industry) => industry.id),
          countries: graphFilterCountries.map((country) => country.id),
          connections: extraFilter,
        },
      }).then((data) => {
        let fullData = addNodeDOMImage(data);
        fullData = addNodeNeighbors(fullData);
        fullData = setDataDepth(fullData);
        setGraphData(fullData);
        setIsGraphReady(true);
      });
    }
  };

  useEffect(() => {
    if (entityId === user.id) {
      // load all user connections if primary topic linked to user has no mainEntity
      setExtraFilter('allConnections');
    }
    setCenterId(entityId);
  }, [entityId]);

  // run applyFilters only after centerId is updated in react render clicle
  useEffect(() => {
    if (centerId !== null) {
      applyFilters();
    }
  }, [centerId]);

  useEffect(() => {
    applyFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [
    graphFilterStrengths,
    graphFilterSecondaryStrengths,
    graphFilterIndustries,
    graphFilterCountries,
    extraFilter,
  ]);

  return (
    <>
      {isGraphReady ? (
        <>
          <ForceGraph
            ref={graphRef}
            width={windowSize.width}
            height={windowSize.height}
            graphData={graphData}
            nodeId="id"
            nodeVal={(node) => {
              return node.width * 0.5;
            }}
            cooldownTime={2000}
            linkDirectionalArrowLength={0}
            nodeCanvasObject={drawCanvasNodes}
            linkCanvasObject={drawCanvasLinks}
            onNodeHover={handleNodeHover}
            onLinkHover={handleLinkHover}
            onNodeClick={handleNodeClick}
            onLinkClick={handleLinkClick}
            onNodeRightClick={(e) => {
              if (e.id === centerId) return;
              openMenu(e, 'NODE');
            }}
            onLinkRightClick={(e) => {
              openMenu(e, 'LINK');
            }}
            onBackgroundRightClick={(e) => {
              openMenu(e, 'BG');
            }}
            onBackgroundClick={resetMenu}
            onZoomEnd={handleZoomEnd}
            onEngineStop={handleEngineStop}
            onEngineTick={handleEngineTick}
            onNodeDrag={() => {
              stopForces(graphRef.current);
            }}
          />
        </>
      ) : (
        <GraphLoader />
      )}
      <GraphMenu
        isOpen={isMenuOpen.status}
        type={isMenuOpen.type}
        target={isMenuOpen.target}
        resetMenu={resetMenu}
        handleLinkClick={handleLinkClick}
        handleSetNodeFocus={handleSetNodeFocus}
        hideEntity={hideEntity}
        hideRelationships={hideRelationships}
        expandNode={expandNode}
      />
      <Controls svgRef={graphRef} />

      {graphData && graphData.nodes && (
        <GraphFilters
          entityId={centerId}
          graphActiveFilter={graphActiveFilter}
          setGraphActiveFilter={setGraphActiveFilter}
          actorTypes={actorTypes}
          setActorTypes={setActorTypes}
          nodeType={getNodeType(centerId)}
          nodeCount={graphData.nodes.length}
          data={graphData}
          setGraphData={setGraphData}
          currentGraphFilterStrengths={graphFilterStrengths}
          setGraphFilterStrengths={setGraphFilterStrengths}
          currentGraphFilterSecondaryStrengths={graphFilterSecondaryStrengths}
          setGraphFilterSecondaryStrengths={setGraphFilterSecondaryStrengths}
          graphFilterIndustries={graphFilterIndustries}
          setGraphFilterIndustries={setGraphFilterIndustries}
          graphFilterCountries={graphFilterCountries}
          setGraphFilterCountries={setGraphFilterCountries}
          resetGraphFilter={resetGraphFilter}
          setCenterId={setCenterId}
          extraFilter={extraFilter}
          setExtraFilter={setExtraFilter}
          enableSecondaryFilter={enableSecondaryFilter}
        />
      )}
    </>
  );
};

export default Graph;
