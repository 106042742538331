import { Loading } from '@axis/xyz.app.loading';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import { useSearch } from '../../Search';
import css from './SearchBar.module.css';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import { ACTIONS } from '../../../../v2/components/Filter/filterReducer';
import useDebounce from '../../../../hooks/useDebounce';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext';
import notify from '../../../../lib/notify';
import { fetchAutocomplete } from '../../api';
import { useApolloClient } from '../../../../hooks/useApolloClient';
import { flattenTree } from '../../../../utils';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import { filterCountriesByNameAndAltNames } from '../../../../v2/components/Filters/CountryFilter/utils';

const DEBOUNCE_TIME = 600;

const getFocusableNodes = () =>
  document.querySelectorAll('[data-type="search-autocomplete-focus-item"]');

const AtSignSuggestionItem = () => (
  <div
    data-cy="search-suggestions-autocomplete-atsign-suggestions"
    className={css.suggestionsGuideItem}
    tabIndex="-1"
  >
    Search for results connected to a specific entity.
  </div>
);

const NoSuggestionsItem = () => (
  <div
    data-cy="search-suggestions-autocomplete-placeholder"
    className={css.suggestionsPlaceholderItem}
    tabIndex="-1"
  >
    No suggestions match your search. Please try something else
  </div>
);

const EnterToSearchSuggestionItem = ({ text }) => (
  <div
    data-cy="search-suggestions-autocomplete-enter-to-search"
    className={css.suggestionsGuideItem}
    tabIndex="-1"
  >
    Press Enter to search for &quot;{text}&quot; in Axis.
  </div>
);

const SuggestionItem = ({ suggestion, onClick }) => {
  return (
    <div
      data-cy="search-suggestions-autocomplete"
      data-type="search-autocomplete-focus-item"
      data-entity-type={suggestion.entityType}
      className={css.suggestionsItem}
      onClick={() => onClick(suggestion)}
      tabIndex="-1"
    >
      <EntityPill
        className={css.suggestionPill}
        name={suggestion.name}
        shortName={suggestion.shortestName}
        image={suggestion.image}
        type={suggestion.entityType}
        size="medium"
      />
    </div>
  );
};

const SearchBar = () => {
  const {
    searchEntities,
    dispatch,
    searchIndustries,
    searchCountries,
    onSearch,
    activeSearchText,
    clearAll,
  } = useSearch();

  const client = useApolloClient();
  const router = useRouteInformation();
  const searchQuery = router.query.get('search');
  const { topics, briefs: industries, subscribedCountries } = useAxisData();

  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focus, setFocus] = useState({ active: false, index: 0 });
  const debounceSearchText = useDebounce(searchText, DEBOUNCE_TIME);
  const [isSuggestionsLoading, setSuggestionsLoading] = useState(false);
  const flatIndustries = useMemo(() => flattenTree(industries), [industries]);

  const ref = useRef();
  const inputRef = useRef();

  const runSearch = (text) => {
    onSearch(text);
  };

  useEffect(() => {
    if (activeSearchText !== searchText) {
      setSearchText(activeSearchText);
    }
  }, [activeSearchText]);

  // Exclude the already applied filters
  const validSuggestions = suggestions.filter(({ id }) => {
    // Other types of suggestions (APCs)
    return !searchEntities.some((entity) => entity.id === id);
  });

  const isIndustryOrCountryFilter = searchText.startsWith('#');

  const getIndustriesAndCountriesSuggestions = (searchTerm) => {
    const query = searchTerm.substring(1);
    const filteredIndustries = flatIndustries.filter(({ name }) =>
      name.toLowerCase().startsWith(query.toLowerCase()),
    );

    const filteredCountries = filterCountriesByNameAndAltNames(
      subscribedCountries,
      query,
    );

    const countriesSuggestions = filteredCountries.map((country) => ({
      id: country.id,
      name: country.name,
      briefType: 'country',
    }));

    const industriesSuggestions = filteredIndustries.map((industry) => ({
      id: industry.id,
      name: industry.name,
      briefType: 'industry',
    }));

    return [...industriesSuggestions, ...countriesSuggestions].slice(0, 6);
  };

  const fetchAutocompleteResults = async ({
    search,
    countries,
    targetEntities,
  }) => {
    let symbol = '';
    let term = search;

    if (isIndustryOrCountryFilter || search.startsWith('@')) {
      symbol = search.substring(0, 1);
      term = search.substring(1);
    }

    if (!term && symbol === '@') {
      setSuggestionsLoading(false);
      return;
    }

    const variables = {
      term,
      symbol,
      topics: countries,
      entities: targetEntities,
    };

    sendEvent('advanced_search_autocomplete', {
      description: 'Autocomplete request search',
      search,
      term,
      symbol,
    });

    setSuggestionsLoading(true);

    try {
      if (isIndustryOrCountryFilter) {
        const briefSuggestions = getIndustriesAndCountriesSuggestions(search);
        setSuggestions(briefSuggestions);
        setSuggestionsLoading(false);
        return;
      }

      const result = await fetchAutocomplete({
        client,
        ...variables,
      });

      const { entities = [] } = result;

      // Hack, use a hook to ensure we have the latest search text
      let currentSearch = '';
      setSearchText((currentSearchText) => {
        currentSearch = currentSearchText;
        return currentSearchText;
      });

      if (search !== currentSearch) {
        setSuggestionsLoading(false);
        return;
      }

      setSuggestions(entities);
    } catch (error) {
      notify.error('There was an error retrieving the suggestions.');
    }
    setSuggestionsLoading(false);
  };

  useEffect(() => {
    if (searchQuery) {
      setSearchText(searchQuery);
      runSearch(searchQuery);
    }
  }, []);

  useEffect(() => {
    setSuggestions([]);
  }, [searchText]);

  useEffect(() => {
    if (debounceSearchText) {
      fetchAutocompleteResults({
        search: debounceSearchText,
        countries: searchCountries.map((c) => c.id),
        targetEntities: searchEntities.map((e) => e.id),
      });
    }
  }, [debounceSearchText]);

  useEffect(() => {
    const elements = getFocusableNodes().length;
    const opts = elements + 1;
    const { active, index } = focus;
    const upHandler = (event) => {
      if (event.key === 'ArrowDown') {
        setFocus({ active: true, index: index + 1 >= opts ? 0 : index + 1 });
        event.preventDefault();
      }
      if (event.key === 'ArrowUp') {
        setFocus({ active: true, index: index - 1 < 0 ? opts - 1 : index - 1 });
        event.preventDefault();
      }
      if (event.key === 'Enter' && active && index > 0) {
        const element = getFocusableNodes().item(index - 1);
        if (element) element.click();
        event.preventDefault();
      }
      if (event.key === 'Escape' && active && index > 0) {
        setFocus({ active: true, index: 0 });
        event.preventDefault();
      }
      if (event.key === 'Escape' && index === 0) {
        setFocus({ active: false, index: 0 });
        if (inputRef.current) inputRef.current.blur();
        event.preventDefault();
      }
    };
    const downHandler = (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    ref.current.addEventListener('keyup', upHandler);
    ref.current.addEventListener('keydown', downHandler);
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keyup', upHandler);
        ref.current.removeEventListener('keydown', downHandler);
      }
    };
  }, [focus, validSuggestions.length]);

  useEffect(() => {
    const { index, active } = focus;
    if (!active) return;
    // Index: 0 - focus on the <input />
    if (index === 0) {
      inputRef.current.focus();
    }
    // Focus on a search result
    else {
      const element = getFocusableNodes().item(index - 1);
      if (element) element.focus();
    }
  }, [focus]);

  // Reset focus when results change
  useEffect(() => {
    setFocus({ active: false, index: 0 });
  }, [validSuggestions.length]);

  const handleSuggestionClick = (suggestion) => {
    const payload = {};

    if (suggestion.entityType === 'Brief') {
      if (suggestion.briefType === 'Country') {
        const topicId = suggestion.ids[0];
        const topic = topics.find((t) => t.id === topicId);
        if (topic) {
          payload.countries = [...searchCountries, topic];
        }
      } else {
        payload.industries = [...searchIndustries, suggestion];
      }
    } else {
      payload.entities = [...searchEntities, suggestion];
    }
    setSearchText('');

    dispatch({
      type: ACTIONS.RUN_SEARCH,
      payload,
    });
  };

  const handleRemove = ({ filter, type }) => {
    if (type === 'Country') {
      dispatch({
        type: ACTIONS.UPDATE_COUNTRIES,
        payload: searchCountries.filter((c) => c.id !== filter.id),
      });
    } else if (type === 'Brief') {
      dispatch({
        type: ACTIONS.UPDATE_INDUSTRIES,
        payload: searchIndustries.filter((i) => i.name !== filter.name),
      });
    } else {
      dispatch({
        type: ACTIONS.UPDATE_ENTITIES,
        payload: searchEntities.filter((e) => e.id !== filter.id),
      });
    }
  };

  const handleClearSearchBar = () => {
    setSearchText('');
    dispatch({
      type: ACTIONS.UPDATE_ANY,
      payload: {
        industries: [],
        countries: [],
        entities: [],
        activeFilter: 'all',
      },
    });
    clearAll();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      runSearch(searchText);
      e.target.blur();
    }
  };

  const onBriefSelected = (brief) => {
    if (brief.briefType === 'country') {
      dispatch({
        type: ACTIONS.UPDATE_COUNTRIES,
        payload: [...searchCountries, brief],
      });
    }

    if (brief.briefType === 'industry') {
      dispatch({
        type: ACTIONS.UPDATE_INDUSTRIES,
        payload: [...searchIndustries, brief],
      });
    }

    setSearchText('');
    onSearch('');
  };

  const isEmptySearch = searchText.trim().length === 0;
  const isAtsignSearch = searchText === '@';
  const isActiveSearch = searchText.trim().length > 1;

  const entitySuggestions = validSuggestions.filter((suggestion) =>
    ['Actor', 'Plot'].includes(suggestion.entityType),
  );

  const briefs = isIndustryOrCountryFilter ? validSuggestions : [];

  return (
    <div ref={ref} className={css.search}>
      <div className={css.searchArea}>
        {searchEntities.map((entity) => (
          <EntityPill
            key={entity.id}
            id={entity.id}
            autoOpenDossier
            name={entity.name}
            shortName={entity.shortName}
            type={entity.entityType || entity.type}
            image={entity.image}
            onClose={() =>
              handleRemove({ filter: entity, type: entity.entityType })
            }
          />
        ))}
        <input
          ref={inputRef}
          data-cy="search-input"
          className={css.input}
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Use ‘#' to filter for industries and '@’ for actors"
        />

        <span
          data-cy="search-clear"
          className={css.clear}
          onClick={handleClearSearchBar}
        >
          Clear all
        </span>
      </div>
      {isEmptySearch && <div className={css.recent} />}
      {!isEmptySearch && (
        <div className={css.suggestions}>
          {isAtsignSearch && <AtSignSuggestionItem />}
          {isActiveSearch && <EnterToSearchSuggestionItem text={searchText} />}
          {isIndustryOrCountryFilter && (
            <>
              <p className={css.suggestionsTitle}>Suggestions</p>
              {briefs.map((brief) => (
                <div
                  key={brief.id}
                  data-cy="search-suggestions-autocomplete"
                  data-type="search-autocomplete-focus-item"
                  data-entity-type={brief.entityType}
                  className={css.suggestionsItem}
                  onClick={() => onBriefSelected(brief)}
                  tabIndex="-1"
                >
                  <BriefPill
                    name={brief.name}
                    className={css.brief}
                    isGeography={brief.briefType === 'country'}
                  />
                </div>
              ))}
            </>
          )}
          {isActiveSearch && (
            <>
              {isSuggestionsLoading && (
                <>
                  <div className={css.loading}>
                    <Loading className={css.loadingSpinner} />
                  </div>
                </>
              )}
              {!isSuggestionsLoading && entitySuggestions.length > 0 && (
                <>
                  <p className={css.suggestionsTitle}>Entity suggestions</p>
                  {entitySuggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.id}
                      suggestion={suggestion}
                      onClick={() => handleSuggestionClick(suggestion)}
                    />
                  ))}
                </>
              )}

              {!isSuggestionsLoading && validSuggestions.length === 0 && (
                <NoSuggestionsItem />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
