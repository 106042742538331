import React from 'react';

import css from './Source.module.css';
import { getHost } from '../../../lib/facets/attachment';
import OutlineSource from '../../../icons/SourceOutline';
import PdfIcon from '../../../icons/PdfIcon';

function Source({ src }) {
  const resource = src?.startsWith('http') ? src : `https://${src}`;
  const label = getHost(src);
  const icon = label === 'Document' ? <PdfIcon /> : <OutlineSource />;

  return (
    <a className={css.source} rel="noreferrer" target="_blank" href={resource}>
      {label}
      {icon}
    </a>
  );
}

export default Source;
