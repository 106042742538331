import React, { useCallback, useEffect, useRef } from 'react';
import { Loading } from '@axis/xyz.app.loading';
import {
  describeActiveFilters,
  getLabelFromType,
  getSearchResultElements,
  getTotalSearchResults,
  isLoadingResults,
} from '../../../../lib/search';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext';
import {
  EntityCategoryResults,
  FacetCategoryResults,
  ListResults,
} from '../CategoryResults/CategoryResults';
import css from './SearchResults.module.css';
import { useSearch } from '../../Search';
import Search from '../../../../icons/Search';
import AiSuggestions from '../AiSuggestions/AiSuggestions';

const ResultsOverview = () => {
  const {
    entityResults,
    facetResults,
    updateSearchType,
    isEntityResultsLoading,
    isFacetResultsLoading,
    counts,
  } = useSearch();

  const handleCategoryClick = (type) => {
    updateSearchType(type);
  };

  const getCount = (type) => {
    if (!counts) return '';
    return counts[type];
  };

  return (
    <div data-cy="search-results-overview" className={css.overview}>
      {!isEntityResultsLoading ? (
        <>
          <AiSuggestions />

          <EntityCategoryResults
            key="actors"
            name={getLabelFromType('actors')}
            total={getCount('actors')}
            entities={entityResults}
            onClick={() => handleCategoryClick('actor')}
          />
        </>
      ) : (
        <div className={css.loading}>
          <Loading className={css.loadingSpinner} />
        </div>
      )}
      {!isFacetResultsLoading ? (
        facetResults.map((category) => (
          <FacetCategoryResults
            key={category.type}
            type={category.type}
            name={getLabelFromType(category.type)}
            total={getCount(category.type)}
            facets={category.data}
            onClick={() => handleCategoryClick(category.type)}
          />
        ))
      ) : (
        <div className={css.loading}>
          <Loading className={css.loadingSpinner} />
        </div>
      )}
    </div>
  );
};

const ResultsList = () => {
  const {
    isEntityResultsLoading,
    isFacetResultsLoading,
    searchTypes,
    visibleResultType,
    entityResults,
    facetResults,
    setPagination,
    pagination,
    counts,
    isCountLoading,
    hasMoreToLoad,
  } = useSearch();
  const loader = useRef(null);

  const {
    type,
    total,
    results = [],
  } = getSearchResultElements({
    entities: entityResults,
    facets: facetResults,
    resultType: visibleResultType,
    category: searchTypes[0],
    counts,
  });

  const isLoading = isLoadingResults({
    isLoadingFacets: isFacetResultsLoading,
    isLoadingEntities: isEntityResultsLoading,
    resultType: visibleResultType,
  });

  const remaining = total - (results ? results.length : 0);

  const handleObserver = useCallback(
    (entries) => {
      if (visibleResultType === 'entity' && !hasMoreToLoad) return;
      if (isLoading || isCountLoading || results?.length === 0) return;

      const target = entries[0];
      if (!target.isIntersecting || remaining <= 0) return;

      const newPagination = {
        skip: pagination.skip + 10,
        limit: Math.min(10, remaining),
      };

      setPagination(newPagination);
    },
    [remaining, isLoading, results.length, pagination, isCountLoading],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const element = loader && loader.current;
    if (!element) return () => {};

    observer.observe(element);
    return () => observer.unobserve(element);
  }, [handleObserver]);

  if (!results || results.length === 0) return <ResultsEmpty />;

  return (
    <div data-cy="search-results-list" className={css.list}>
      <ListResults
        isLoading={isLoading}
        resultType={visibleResultType}
        type={type}
        remaining={remaining}
        results={results}
        onClick={(limit) => setPagination({ skip: results.length, limit })}
      />
      <div ref={loader} />
    </div>
  );
};

const ResultsEmpty = () => {
  const {
    activeSearch,
    searchTypes,
    searchIndustries,
    searchEntities,
    searchCountries,
  } = useSearch();

  const filterDescription = describeActiveFilters({
    searchTypes,
    searchIndustries,
    searchEntities,
    searchCountries,
  });

  useEffect(() => {
    sendEvent('advanced_search_no_entities_results', {
      description: 'Full page search returned no results',
      search: activeSearch,
      searchDescription: filterDescription,
    });
  }, []);

  return (
    <div className={css.noResults}>
      <div className={css.icon}>
        <Search />
      </div>
      <div className={css.noResultsTitle}>No results</div>
      <div className={css.noResultsSubTitle}>
        Try searching to see some results
      </div>
    </div>
  );
};

const ResultsContent = () => {
  const {
    entityResults,
    facetResults,
    visibleResultType,
    isEntityResultsLoading,
    isFacetResultsLoading,
    aiEntitySuggestions,
  } = useSearch();

  const totalResults = getTotalSearchResults({
    entities: entityResults,
    facets: facetResults,
    resultType: visibleResultType,
  });

  const isLoading = isLoadingResults({
    isLoadingFacets: isFacetResultsLoading,
    isLoadingEntities: isEntityResultsLoading,
    resultType: visibleResultType,
  });

  const hasAiSuggestions =
    aiEntitySuggestions && aiEntitySuggestions.length > 0;

  if (!totalResults && !isLoading && !hasAiSuggestions) return <ResultsEmpty />;
  if (visibleResultType === 'overview') return <ResultsOverview />;
  return <ResultsList />;
};

const SearchResults = () => {
  const {
    isEntityResultsLoading,
    isFacetResultsLoading,
    entityResults,
    facetResults,
    visibleResultType,
    searchTypes,
  } = useSearch();

  if (searchTypes.length === 0) return null;

  const totalResults = getTotalSearchResults({
    entities: entityResults,
    facets: facetResults,
    resultType: visibleResultType,
  });

  const isLoading = isLoadingResults({
    isLoadingFacets: isFacetResultsLoading,
    isLoadingEntities: isEntityResultsLoading,
    resultType: visibleResultType,
  });

  if (isLoading && totalResults === 0) {
    return (
      <div className={css.loading}>
        <Loading className={css.spinner} />
      </div>
    );
  }

  return (
    <div data-cy="search-results-section" className={css.results}>
      <ResultsContent />
    </div>
  );
};

export default SearchResults;
