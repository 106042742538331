/* eslint-disable prefer-destructuring */
import {
  filterTypes,
  getActorSubFilters,
  getEventSubFilters,
  getRegulationSubfilters,
} from '.';

export const filterDefaultState = {
  search: '',
  industries: [],
  countries: [],
  dates: [null, null],
  activeFilter: filterTypes.All,
  entities: [],
  isActiveFilterCollapsed: false,
  regulationTypes: getRegulationSubfilters(),
  eventTypes: getEventSubFilters(),
  actorTypes: getActorSubFilters(),
  runSearch: null,
};

export const ACTIONS = {
  UPDATE_INDUSTRIES: 'UPDATE_INDUSTRIES',
  UPDATE_COUNTRIES: 'UPDATE_COUNTRIES',
  UPDATE_DATES: 'UPDATE_DATES',
  UPDATE_ACTIVE_FILTER: 'UPDATE_ACTIVE_FILTER',
  UPDATE_ENTITIES: 'UPDATE_ENTITIES',
  UPDATE_REGULATION_TYPES: 'UPDATE_REGULATION_TYPES',
  UPDATE_EVENT_TYPES: 'UPDATE_EVENT_TYPES',
  UPDATE_ACTOR_TYPES: 'UPDATE_ACTOR_TYPES',
  UPDATE_ANY: 'UPDATE_ANY',
  UPDATE_ACTIVE_COLLAPSE: 'UPDATE_ACTIVE_COLLAPSE',
  UPDATE_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
  RUN_SEARCH: 'RUN_SEARCH', // run search frm searchbar
};

/**
 * @param state
 * @param {{type: ACTION, payload?: any }} action
 */
export const filterReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_INDUSTRIES:
      return { ...state, industries: action.payload };
    case ACTIONS.UPDATE_COUNTRIES:
      return { ...state, countries: action.payload };
    case ACTIONS.UPDATE_DATES:
      return { ...state, dates: action.payload };
    case ACTIONS.UPDATE_ACTIVE_FILTER: {
      let dates = state.dates;

      // reset dates if activeFilter is not events or quotes
      if (![filterTypes.Events, filterTypes.Quotes].includes(action.payload)) {
        dates = [null, null];
      }

      return {
        ...state,
        dates,
        isActiveFilterCollapsed: false,
        activeFilter: action.payload,
        regulationTypes: getRegulationSubfilters(),
        eventTypes: getEventSubFilters(),
        actorTypes: getActorSubFilters(),
      };
    }
    case ACTIONS.UPDATE_ENTITIES:
      return { ...state, entities: action.payload };
    case ACTIONS.UPDATE_REGULATION_TYPES:
      return { ...state, regulationTypes: action.payload };
    case ACTIONS.UPDATE_EVENT_TYPES:
      return { ...state, eventTypes: action.payload };
    case ACTIONS.UPDATE_ACTOR_TYPES:
      return { ...state, actorTypes: action.payload };
    case ACTIONS.UPDATE_ANY:
      return { ...state, ...action.payload };
    case ACTIONS.UPDATE_ACTIVE_COLLAPSE:
      return { ...state, isActiveFilterCollapsed: action.payload };
    case ACTIONS.UPDATE_SEARCH_TEXT:
      return { ...state, search: action.payload };
    case ACTIONS.RUN_SEARCH: {
      const newState = { ...state, ...action.payload };
      return { ...newState, runSearch: action.payload };
    }
    default:
      return state;
  }
};
