import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { mergeFacetSearchResults } from '../../../lib/search';
import { getEventAndRegulationFilterCategories } from '../helper';
import QUERY_SEARCH_FACETS from '../../../queries/searchEventsAndRegulations';

function useSearchFacets() {
  const [facetResults, setFacetResults] = useState([]);
  const [variables, setVariables] = useState();

  const { loading } = useQuery(QUERY_SEARCH_FACETS, {
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        const results = data.searchEventsAndRegulations;
        setFacetResults((currentResults) =>
          mergeFacetSearchResults({
            currentResults,
            newResults: results,
            facetType: variables.type,
          }),
        );
      }
    },
  });

  const fetchFacets = ({
    search = '',
    type,
    countries,
    industries,
    entities,
    pageInfo,
    dates,
    regulationTypes,
  }) => {
    if (type === 'actors') return; // Early exit if type is 'actors'

    const vars = {
      name: search,
      filterIndustries: industries,
      filterEntities: entities,
      topics: countries,
      entities,
      type,
      filterCategories: getEventAndRegulationFilterCategories(
        type,
        pageInfo,
        regulationTypes,
      ),
      id: window.crypto.randomUUID(),
    };

    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      vars.startDate = startDate;
      vars.endDate = endDate;
    }

    setVariables(vars);
  };

  return {
    facetResults,
    fetchFacets,
    setFacetResults,
    isFacetResultsLoading: loading,
  };
}

export default useSearchFacets;
