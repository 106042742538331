import * as React from 'react';

function OutlineSource(props) {
  return (
    <svg
      width={13}
      height={13}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 3h6m0 0v6m0-6L3 9" stroke="#929AA3" strokeWidth={1.125} />
    </svg>
  );
}

export default OutlineSource;
