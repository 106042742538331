import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import GET_FEED from '../../queries/getFeed';
import { getRegulationKey } from '../../v2/components/Filter';

const normalizeFeedCounts = (total = []) => {
  return total.map((item) => ({
    ...item,
    type: getRegulationKey(item.type),
  }));
};

/*
  This approach was choose due useQuery internal logic that cancels on going requests if variables change 
*/
function useFeedApi(initialVariables = {}) {
  const [variables, setVariables] = useState(initialVariables);
  const [results, setResults] = useState([]);
  const [totals, setTotals] = useState([]);

  const { loading } = useQuery(GET_FEED, {
    variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { total, updates } = data.getFeed;
      setTotals(normalizeFeedCounts(total));
      setResults((currentResults) =>
        uniqBy([...currentResults, ...updates], 'id'),
      );
    },
  });

  const fetchFeed = (newVariables) => {
    setVariables({
      ...newVariables,
      id: window.crypto.randomUUID(),
    });
  };

  return {
    fetchFeed,
    isFeedLoading: loading,
    totals,
    results,
    setResults,
    setTotals,
  };
}

export default useFeedApi;
