import { DateTime } from 'luxon';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const parseDate = (date) => {
  if (!date) return DateTime.invalid('No date provided');
  // Parse as ISO and SQL for maximum format compatibility
  if (DateTime.isDateTime(date)) return date;
  const dateISO = DateTime.fromISO(date, { zone: 'utc' });
  if (dateISO.isValid) return dateISO;
  const dateSQL = DateTime.fromSQL(date, { zone: 'utc' });
  if (dateSQL.isValid) return dateSQL;
  // Also try to parse a raw milliseconds timestamp
  if (!Number.isNaN(date)) {
    const dateMillis = DateTime.fromMillis(+date);
    if (dateMillis.isValid) return dateMillis;
  }
  return DateTime.invalid('Invalid date provided');
};

export const formatDate = (date) => {
  const value = parseDate(date);
  if (!value.isValid) return '';
  return value.setLocale('en').toLocaleString(DateTime.DATE_FULL);
};

export const formatDateMed = (date) => {
  const value = parseDate(date);
  if (!value.isValid) return '';
  return value.setLocale('en').toLocaleString(DateTime.DATE_MED);
};

export const formatDateShort = (date) => {
  const value = parseDate(date);
  if (!value.isValid) return '';
  return value.setLocale('en').toFormat('MMM, yyyy');
};

export const formatDateSQL = (date) => {
  const value = parseDate(date);
  if (!value.isValid) return '';
  return value.setLocale('en').toSQLDate();
};

export const getMonthText = (date) => {
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getDayText = (date) => {
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

/**
 * @param {*} date
 * @returns {date} the Date Object from the start of the date Month
 */
export const getMonthFromDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const isSameMonth = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
};

export const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isSameDate = (date1, date2) => {
  return date1 === date2;
};

export const sortElementsByRecentDate = (a, b) => {
  if (a.endDate && b.endDate) {
    return new Date(b.endDate) - new Date(a.endDate);
  }
  if (!a.endDate && !b.endDate) {
    if (a.startDate && b.startDate) {
      return new Date(b.startDate) - new Date(a.startDate);
    }
    if (a.startDate) return -1;
    if (b.startDate) return 1;
    return 0;
  }
  if (!a.endDate && a.startDate) return -1;
  if (!b.endDate && b.startDate) return 1;
  if (a.startDate && b.startDate) {
    return new Date(b.startDate) - new Date(a.startDate);
  }
  if (a.startDate) return -1;
  if (b.startDate) return 1;
  return 0;
};

/* Temporally */
export const isRegulationDateValid = (inputDate) => {
  if (!inputDate) return false;

  const dateToCompare = new Date(inputDate);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Set the minimum allowed date to January 1, 1900
  const minDate = new Date(1900, 0, 1);
  return dateToCompare > minDate && dateToCompare < tomorrow;
};
