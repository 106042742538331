import * as React from 'react';

function ComplianceOutline(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M1.334 14v-1.333h6v-7.45A1.946 1.946 0 016.117 4H4.001l2 4.667c0 .555-.228 1.028-.684 1.417-.455.388-1.005.583-1.65.583-.644 0-1.194-.195-1.65-.583-.455-.39-.683-.862-.683-1.417l2-4.667H2.001V2.667h4.116c.134-.39.373-.709.717-.959s.733-.375 1.167-.375c.433 0 .822.125 1.166.375.345.25.584.57.717.959h4.117V4h-1.334l2 4.667c0 .555-.227 1.028-.683 1.417-.456.388-1.006.583-1.65.583-.644 0-1.194-.195-1.65-.583-.456-.39-.683-.862-.683-1.417L12 4H9.884a1.946 1.946 0 01-1.217 1.217v7.45h6V14H1.334zm9.75-5.333h2.5l-1.25-2.9-1.25 2.9zm-8.667 0h2.5l-1.25-2.9-1.25 2.9zM8.001 4a.647.647 0 00.475-.191.647.647 0 00.191-.476.647.647 0 00-.191-.475.647.647 0 00-.475-.191.645.645 0 00-.475.191.646.646 0 00-.192.475c0 .19.064.348.192.476A.645.645 0 008.001 4z"
          fill="#42474D"
        />
      </g>
    </svg>
  );
}

export default ComplianceOutline;
