import * as React from 'react';

function ScoreNeutral(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M2 3H10V5H2z" />
      <path fill="#fff" d="M2 7H10V9H2z" />
    </svg>
  );
}

export default ScoreNeutral;
