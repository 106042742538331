import React, { useEffect, useState } from 'react';
import { StrengthFilter } from '../StrengthFilter/StrengthFilter';
import GraphExtraFilter from '../GraphExtraFilter/GraphExtraFilter';
import FilterIcon from '../../../../icons/FilterIcon/FilterIcon';
import css from './GraphFilters.module.css';
import IndustryFilterWrapper from '../IndustryFilterWrapper/IndustryFilterWrapper';
import { SecondaryStrengthFilter } from '../SecondaryStrengthFilter/SecondaryStrengthFilter';
import { useSharedState } from '../../../../contexts/SharedStateContext';
import CountryFilterWrapper from '../CountryFilterWrapper/CountryFilterWrapper';
import Header from '../../../../v2/components/Filter/Header/Header';
import FilterType from '../../../../v2/components/Filter/Types/FilterType/FilterType';
import Actors from '../../../../v2/components/Filter/Types/Actors/Actors';
import { filterTypes, toggleSelection } from '../../../../v2/components/Filter';

const ButtonFilter = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      data-cy="feed-open-filters"
      className={css.toggleButton}
    >
      <FilterIcon />
    </button>
  );
};

const GraphFilters = ({
  entityId,
  setGraphFilterStrengths,
  currentGraphFilterStrengths,
  setGraphFilterSecondaryStrengths,
  currentGraphFilterSecondaryStrengths,
  setGraphFilterEntities,
  graphFilterIndustries,
  setGraphFilterIndustries,
  graphFilterCountries,
  setGraphFilterCountries,
  setCenterId,
  extraFilter,
  setExtraFilter,
  resetGraphFilter,
  enableSecondaryFilter,
  graphActiveFilter,
  setGraphActiveFilter,
  actorTypes,
  setActorTypes,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { selectedSavedSearch, setSelectedSavedSearch } = useSharedState();

  const onFilterClicked = (type) => {
    setGraphActiveFilter(type);
  };

  const applySavedSearch = (savedSearch) => {
    const searchData = JSON.parse(savedSearch.search_data);
    setGraphFilterIndustries(searchData.filterIndustries);
    if (searchData.filterEntities.length > 0) {
      setCenterId(searchData.filterEntities[0].id);
    }
  };

  const handleActorOptionChange = (node) => {
    const payload = toggleSelection(actorTypes, node.id);
    setActorTypes([...payload]);
  };

  // Apply saved search when user clicks on leftPanel
  useEffect(() => {
    if (!selectedSavedSearch) return;

    applySavedSearch(selectedSavedSearch);
  }, [selectedSavedSearch]);

  // Remove saved search when unMounting component
  useEffect(() => () => setSelectedSavedSearch(null), []);

  return (
    <div className={css.main} data-status={isOpen ? 'open' : 'closed'}>
      {isOpen ? (
        <div className={css.panel}>
          <Header onClose={() => setOpen(false)} />

          <div className={css.filters}>
            <FilterType
              label="All Results"
              isLoadingCounts={false}
              type={filterTypes.All}
              activeFilter={graphActiveFilter}
              onClick={onFilterClicked}
            />

            <Actors
              isLoadingCounts={false}
              options={actorTypes}
              counts={{}}
              activeFilter={graphActiveFilter}
              onOptionChange={handleActorOptionChange}
              onClick={onFilterClicked}
            />

            <div className={css.divider} />

            <IndustryFilterWrapper
              graphFilterIndustries={graphFilterIndustries}
              setGraphFilterIndustries={setGraphFilterIndustries}
            />
            <CountryFilterWrapper
              graphFilterCountries={graphFilterCountries}
              setGraphFilterCountries={setGraphFilterCountries}
            />
            <StrengthFilter
              currentGraphFilterStrengths={currentGraphFilterStrengths}
              setGraphFilterStrengths={setGraphFilterStrengths}
            />
            {enableSecondaryFilter && (
              <SecondaryStrengthFilter
                currentGraphFilterSecondaryStrengths={
                  currentGraphFilterSecondaryStrengths
                }
                setGraphFilterSecondaryStrengths={
                  setGraphFilterSecondaryStrengths
                }
              />
            )}

            <div className={css.divider} />

            <GraphExtraFilter
              setGraphFilterCategory={setGraphFilterEntities}
              setGraphFilterIndustries={setGraphFilterIndustries}
              centerId={entityId}
              setCenterId={setCenterId}
              extraFilter={extraFilter}
              setExtraFilter={setExtraFilter}
              resetGraphFilter={resetGraphFilter}
            />
          </div>
        </div>
      ) : (
        <ButtonFilter onClick={() => setOpen(true)} />
      )}
    </div>
  );
};

export default GraphFilters;
