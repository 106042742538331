import React, { memo, useMemo } from 'react';

import { filterTypes, toggleSelection } from './index';
import Header from './Header/Header';
import css from './Filter.module.css';
import Actors from './Types/Actors/Actors';
import Events from './Types/Events/Events';
import { buildTreeOptions } from '../../../utils';
import useNavigation from '../../hooks/useNavigation';
import FilterType from './Types/FilterType/FilterType';
import Regulations from './Types/Regulations/Regulations';
import { useAxisData } from '../../../contexts/AxisDataContext';
import { ACTIONS } from './filterReducer';
import SearchActors from '../../../components/Filters/Searchactors/SearchActors';
import IndustryFilter from '../Filters/IndustryFilter/IndustryFilter';
import CountryFilter from '../Filters/CountryFilter/CountryFilter';
import DateFilter from '../Filters/DateFilter/DateFilter';

function Filter({
  dataCy,
  isFilterOpen,
  showDateFilter,
  showActorsOption,
  filterState,
  dispatch,
  counts,
  isLoadingCounts,
}) {
  const { briefs } = useAxisData();
  const { deleteParam } = useNavigation();

  const onFilterClicked = (type) => {
    const isToggleCollapse = type === filterState.activeFilter;

    if (isToggleCollapse) {
      dispatch({
        type: ACTIONS.UPDATE_ACTIVE_COLLAPSE,
        payload: !filterState.isActiveFilterCollapsed,
      });
      return;
    }

    dispatch({ type: ACTIONS.UPDATE_ACTIVE_FILTER, payload: type });
  };

  const handleRegulationOptionChange = (node) => {
    const payload = toggleSelection(filterState.regulationTypes, node.id);
    dispatch({ type: ACTIONS.UPDATE_REGULATION_TYPES, payload });
  };

  const handleEventOptionChange = (node) => {
    const payload = toggleSelection(filterState.eventTypes, node.id);
    dispatch({ type: ACTIONS.UPDATE_EVENT_TYPES, payload });
  };

  const handleActorOptionChange = (node) => {
    const payload = toggleSelection(filterState.actorTypes, node.id);
    dispatch({ type: ACTIONS.UPDATE_ACTOR_TYPES, payload });
  };

  const industriesOptions = useMemo(() => {
    return buildTreeOptions(briefs);
  }, [briefs]);

  const filterStatus = isFilterOpen ? 'expanded' : 'collapsed';
  const showDateFilterConditions =
    showDateFilter &&
    [filterTypes.Events, filterTypes.Quotes, filterTypes.Appointments].includes(
      filterState.activeFilter,
    );

  return (
    <div data-cy={dataCy} data-status={filterStatus} className={css.sidebar}>
      <Header onClose={() => deleteParam('isFilterOpen')} />
      <div className={css.main}>
        <FilterType
          label="All Results"
          count={counts.total}
          isLoadingCounts={isLoadingCounts}
          type={filterTypes.All}
          activeFilter={filterState.activeFilter}
          onClick={onFilterClicked}
        />

        <div className={css.divider} />

        {showActorsOption && (
          <Actors
            counts={isLoadingCounts ? {} : counts}
            isLoadingCounts={isLoadingCounts}
            isActiveFilterCollapsed={filterState.isActiveFilterCollapsed}
            options={filterState.actorTypes}
            activeFilter={filterState.activeFilter}
            onOptionChange={handleActorOptionChange}
            onClick={onFilterClicked}
          />
        )}

        <Regulations
          counts={counts}
          isLoadingCounts={isLoadingCounts}
          options={filterState.regulationTypes}
          activeFilter={filterState.activeFilter}
          isActiveFilterCollapsed={filterState.isActiveFilterCollapsed}
          onOptionChange={handleRegulationOptionChange}
          onClick={onFilterClicked}
        />

        <Events
          count={counts.event}
          isLoadingCounts={isLoadingCounts}
          options={filterState.eventTypes}
          activeFilter={filterState.activeFilter}
          onClick={onFilterClicked}
          onOptionChange={handleEventOptionChange}
        />

        <FilterType
          label="Quotes"
          isLoadingCounts={isLoadingCounts}
          count={counts.quote}
          activeFilter={filterState.activeFilter}
          type={filterTypes.Quotes}
          onClick={onFilterClicked}
        />

        <FilterType
          label="Appointments"
          isLoadingCounts={isLoadingCounts}
          count={counts.appointment}
          activeFilter={filterState.activeFilter}
          type={filterTypes.Appointments}
          onClick={onFilterClicked}
        />

        <div className={css.dividerSpaced} />

        <div className={css.spacing}>
          {showDateFilterConditions && (
            <DateFilter
              dateInterval={filterState.dates}
              setDateInterval={(payload) =>
                dispatch({ type: ACTIONS.UPDATE_DATES, payload })
              }
            />
          )}

          <IndustryFilter
            options={industriesOptions}
            activeIndustryFilters={filterState.industries}
            applyFilters={(payload) =>
              dispatch({ type: ACTIONS.UPDATE_INDUSTRIES, payload })
            }
          />

          <CountryFilter
            activeCountries={filterState.countries}
            setActiveCountries={(payload) =>
              dispatch({ type: ACTIONS.UPDATE_COUNTRIES, payload })
            }
          />

          <SearchActors
            customClass={css.searchActor}
            entities={filterState.entities}
            setEntities={(payload) =>
              dispatch({ type: ACTIONS.UPDATE_ENTITIES, payload })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default memo(Filter);
