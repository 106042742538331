import React, { useState, useEffect } from 'react';
import { useApolloClient } from '../hooks/useApolloClient';

import DossierHeader from '../components/DossierHeader/DossierHeader';
import { useRouteInformation } from '../contexts/RouteInformationContext';
import css from './Dossier.module.css';
import EntityPill from '../components/EntityPill/EntityPill';
import BriefPill from '../components/BriefPill/BriefPill';
import Source from '../components/Source/Source';

import eventIcon from '../icons/event.svg';
import regulationIcon from '../icons/regulation.svg';
import arrowBack from '../icons/arrow_back.svg';
import noteIcon from '../icons/note.svg';

import NoteCard from '../components/NoteCard/NoteCard';
import notify from '../lib/notify';
import createNote from '../queries/createNote';
import getNotesForEventQuery from '../queries/getNotesForEvent';
import { useAxisData } from '../contexts/AxisDataContext';
import DefaultButton from '../components/DefaultButton/DefaultButton';
import { FieldRow } from '../components/FIeldRow/FIeldRow';
import { formatDate } from '../lib/date';
import getNotesForRegulationQuery from '../queries/getNotesForRegulation';

const fetchNotesForEvent = async ({ client, eventId }) => {
  return client
    .query({
      query: getNotesForEventQuery,
      variables: { eventId },
    })
    .then(({ data }) => data.getNotesForEvent);
};

const fetchNotesForRegulation = async ({ client, regulationId }) => {
  return client
    .query({
      query: getNotesForRegulationQuery,
      variables: { regulationId },
    })
    .then(({ data }) => data.getNotesForRegulation);
};

const DossierRequestData = ({ dossierType, onSizeChange, size }) => {
  const client = useApolloClient();

  const router = useRouteInformation();
  const { refetchUserTotalNotes } = useAxisData();

  const [isDataLoading, setDataLoading] = useState(false);

  const [event, setEvent] = useState(null);
  const [regulation, setRegulation] = useState(null);
  const [eventNotes, setEventNotes] = useState([]);
  const [regulationNotes, setRegulationNotes] = useState([]);
  const [toggleUpdate, setToggleUpdate] = useState(false);

  const [noteData, setNoteData] = useState({});

  useEffect(() => {
    // Reset the data
    if (!router.location.state) return;
    setDataLoading(false);
    if (dossierType === 'event') {
      setEvent(router.location.state.event);
      setRegulation(null);
      setRegulationNotes([]);
      setNoteData({
        noteType: 'event',
        targetId: router.location.state.event.id,
      });
      fetchNotesForEvent({
        client,
        eventId: router.location.state.event.id,
      }).then((data) => setEventNotes(data));
    } else if (dossierType === 'regulation') {
      setEvent(null);
      setRegulation(router.location.state.regulation);
      setEventNotes([]);
      setNoteData({
        noteType: 'regulation',
        targetId: router.location.state.regulation.id,
      });
      fetchNotesForRegulation({
        client,
        regulationId: router.location.state.regulation.id,
      }).then((data) => setRegulationNotes(data));
    }
  }, [router.location.state, toggleUpdate]);

  const resetNote = () => {
    setNoteData({
      noteType: dossierType,
      targetId:
        (router.location.state.event && router.location.state.event.id) ||
        (router.location.state.regulation &&
          router.location.state.regulation.id),
    });
    setToggleUpdate(!toggleUpdate);
  };

  const onEntityPillClick = (entityId) => {
    router.openDossier({ dossierType: 'entity', dossierEntityId: entityId });
  };

  const handleSaveNote = () => {
    if (!noteData.note || noteData.note.trim() === '') {
      return notify.error('Note cannot be empty', { position: 'top-right' });
    }
    return client
      .mutate({
        mutation: createNote,
        variables: noteData,
      })
      .then(({ errors, data }) => {
        if (errors || !data || !data.createNote) {
          return notify.error(errors[0].message, { position: 'top-right' });
        }
        resetNote();
        refetchUserTotalNotes();
        return notify.success('Note added successfully', {
          position: 'top-right',
        });
      });
  };

  const handleNoteChange = (e) => {
    setNoteData({
      ...noteData,
      note: e.target.value,
    });
  };

  const handleToggleNoteUpdate = () => {
    setToggleUpdate(!toggleUpdate);
    refetchUserTotalNotes();
  };

  return (
    <DossierHeader
      isLoading={isDataLoading}
      size={size}
      onSizeChange={onSizeChange}
    >
      <div className={css.dossier}>
        <div className={css.content}>
          {((event && event.dataLocation === 'dossier-event-card') ||
            (regulation &&
              regulation.dataLocation === 'dossier-regulation-card')) && (
            <div className={css.returnButton}>
              <DefaultButton
                label={`Back to ${
                  (event && event.dossierEntityName) ||
                  (regulation && regulation.dossierEntityName)
                } Dossier`}
                onClick={() =>
                  onEntityPillClick(
                    (event && event.dossierEntityId) ||
                      (regulation && regulation.dossierEntityId),
                  )
                }
                icon={<img src={arrowBack} alt="back" />}
                className={css.addNoteBackButton}
              />
            </div>
          )}
          <span className={css.headIcon}>
            <img
              data-cy="event-regulation-dossier-back"
              src={dossierType === 'event' ? eventIcon : regulationIcon}
              alt="back"
            />
          </span>
          {event && (
            <div className={css.eventContainer}>
              <p data-cy="event-title" className={css.title}>
                {event.title}
              </p>
              <div className={css.entities}>
                {event.entities.map((entity) => (
                  <EntityPill
                    key={entity.entityId || entity.id}
                    size="small"
                    name={entity.name}
                    shortName={entity.shortestName}
                    image={entity.image}
                    type={entity.type}
                    onClick={() =>
                      onEntityPillClick(entity.entityId || entity.id)
                    }
                    inSubscription={entity.inSubscription}
                    isConnected={entity.isConnected}
                  />
                ))}
              </div>
              <div className={css.entities}>
                {event.briefs?.map((entity) => (
                  <BriefPill key={entity.id} name={entity.name} />
                ))}
                {event.countries?.map((entity) => (
                  <BriefPill key={entity.id} name={entity.name} isGeography />
                ))}
                {event.landscapes?.map((entity) => (
                  <BriefPill key={entity.id} name={entity.name} />
                ))}
              </div>
              {event.sources && event.sources.length > 0 && (
                <p data-cy="source-list" className={css.source}>
                  {event.sources.map((source) => (
                    <Source key={source} source={source} />
                  ))}
                </p>
              )}
            </div>
          )}
          {regulation && (
            <div>
              {regulation && regulation.summarizedName && (
                <FieldRow label="Name" text={regulation.summarizedName} />
              )}
              {regulation && regulation.issuer && regulation.issuer.id && (
                <FieldRow label="Issued By">
                  <EntityPill
                    size="small"
                    type={regulation.issuer.type}
                    image={regulation.issuer.image}
                    name={regulation.issuer.name}
                    shortName={regulation.issuer.shortestName}
                    inSubscription={regulation.issuer.inSubscription}
                    onClick={() => onEntityPillClick(regulation.issuer.id)}
                  />
                </FieldRow>
              )}
              {regulation && regulation.createdAt && (
                <FieldRow
                  label="Date"
                  text={formatDate(regulation.createdAt)}
                />
              )}
              {regulation &&
                regulation.industries &&
                regulation.industries.length > 0 && (
                  <FieldRow
                    label={
                      regulation.industries.length === 1
                        ? 'Industry'
                        : 'Industries'
                    }
                  >
                    <div className={css.tags}>
                      {regulation.industries.map((regulationLink) => (
                        <BriefPill
                          key={regulationLink.id}
                          name={regulationLink.name}
                        />
                      ))}
                    </div>
                  </FieldRow>
                )}
              {regulation && regulation.jurisdiction && (
                <FieldRow label="Jurisdiction">
                  <BriefPill name={regulation.jurisdiction} isGeography />
                </FieldRow>
              )}
            </div>
          )}

          <h1 className={css.noteAreaTitle}>Notes</h1>
          <div className={css.noteArea}>
            <textarea
              className={css.note}
              data-cy="notes-textbox"
              type="text-area"
              placeholder="Add your note..."
              onChange={handleNoteChange}
              value={noteData.note || ''}
            />

            <div className={css.addNoteButtons}>
              <button
                data-cy="notes-cancel-button"
                className={css.cancelButton}
                type="button"
                onClick={() => {}}
              >
                Cancel
              </button>
              <button
                data-cy="notes-save-button"
                className={css.saveButton}
                type="button"
                onClick={handleSaveNote}
              >
                Save
              </button>
            </div>
          </div>
          <hr className={css.line} />
          <div className={css.noteList} data-size={size}>
            {eventNotes.length === 0 && regulationNotes.length === 0 ? (
              <div className={css.noNotes}>
                <img src={noteIcon} alt="noteIcon" />
                <h1>No notes available</h1>
                <h3>You currently have not added any notes.</h3>
              </div>
            ) : null}
            {eventNotes.length
              ? eventNotes.map((note) => (
                  <NoteCard
                    key={`dossier-${note.id}`}
                    id={note.id}
                    note={note.note}
                    date={note.date}
                    userName={note.userName}
                    title={note.title}
                    source={note.source}
                    // eslint-disable-next-line no-underscore-dangle
                    noteType="eventNote"
                    handleToggleNoteUpdate={handleToggleNoteUpdate}
                  />
                ))
              : null}
            {regulationNotes.length
              ? regulationNotes.map((note) => (
                  <NoteCard
                    key={`dossier-${note.id}`}
                    id={note.id}
                    note={note.note}
                    date={note.date}
                    userName={note.userName}
                    title={note.summarized_name}
                    source={note.source}
                    noteType="regulationNote"
                    handleToggleNoteUpdate={handleToggleNoteUpdate}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </DossierHeader>
  );
};

export default DossierRequestData;
