import * as React from 'react';

function ScoreUp(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.912 5.912l-.088.087.087.089.7.712.088.09.09-.09 2.586-2.586v5.911h1.25V4.214l2.587 2.587.089.09.088-.09.7-.713.087-.089-.088-.087-4-4L6 1.823l-.088.089-4 4z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
    </svg>
  );
}

export default ScoreUp;
