export const industryFilterState = {
  query: '',
  options: [],
  isPopupOpen: false,
  selectedOptions: [],
  expandedOptions: [],
  selectedOptionsTree: [], // New state for filtered options
};

export const ACTIONS = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  EXPAND_OPTION: 'EXPAND_OPTION',
  COLLAPSE_OPTION: 'COLLAPSE_OPTION',
  SELECT_OPTION: 'SELECT_OPTION',
  UNSELECT_OPTION: 'UNSELECT_OPTION',
  UPDATE_QUERY: 'UPDATE_QUERY',
  UPDATE_OPTIONS: 'UPDATE_OPTIONS',
  SYNC_SELECTED_OPTIONS: 'SYNC_SELECTED_OPTIONS',
  SELECT_ALL: 'SELECT_ALL',
  DESELECT_ALL: 'DESELECT_ALL',
};

// Function to get a list of IDs of nodes that are not selected based on a list of selected IDs
const getUnselectedIds = (filteredOptions, selectedIds) => {
  const unselectedIds = [];

  const collectUnselectedIds = (option) => {
    if (!selectedIds.includes(option.id)) {
      unselectedIds.push(option.id);
    }

    if (option.children) {
      option.children.forEach(collectUnselectedIds);
    }
  };

  filteredOptions.forEach(collectUnselectedIds);

  return unselectedIds;
};

// Helper function to filter options based on selectedOptions
const filterOptionsBySelected = (options, selectedOptions) => {
  const filterOption = (option) => {
    const children = option.children
      ? option.children.map(filterOption).filter(Boolean)
      : [];

    if (selectedOptions.includes(option.id) || children.length > 0) {
      return {
        ...option,
        children: children.length > 0 ? children : option.children,
      };
    }
    return null;
  };

  return options.map(filterOption).filter(Boolean);
};

const getNodeAndChildIds = (parent) => {
  if (!parent.children.length) return [parent.id];

  const allChildIds = [];

  function traverse(node) {
    allChildIds.push(node.id);

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        traverse(child);
      }
    }
  }

  traverse(parent);
  return allChildIds;
};

/**
 * @param state
 * @param {{type: ACTION, payload?: any }} action
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_OPTIONS:
      return { ...state, options: action.payload };
    case ACTIONS.OPEN_POPUP: {
      const selectedOptions = action.payload.selectedOptions.map(
        ({ id }) => id,
      );
      const selectedOptionsTree = filterOptionsBySelected(
        action.payload.options,
        selectedOptions,
      );
      return {
        ...state,
        isPopupOpen: true,
        selectedOptions,
        selectedOptionsTree,
        expandedOptions: getUnselectedIds(selectedOptionsTree, selectedOptions),
        options: action.payload.options,
      };
    }
    case ACTIONS.CLOSE_POPUP:
      return {
        ...state,
        isPopupOpen: false,
        expandedOptions: [],
        selectedOptions: [],
        query: '',
      };
    case ACTIONS.EXPAND_OPTION:
      return {
        ...state,
        expandedOptions: [...state.expandedOptions, action.payload],
      };
    case ACTIONS.COLLAPSE_OPTION:
      return {
        ...state,
        expandedOptions: state.expandedOptions.filter(
          (item) => item !== action.payload,
        ),
      };
    case ACTIONS.SELECT_OPTION:
      return {
        ...state,
        selectedOptions: [
          ...state.selectedOptions,
          ...getNodeAndChildIds(action.payload),
        ],
      };
    case ACTIONS.UNSELECT_OPTION: {
      const removeSelectionIds = getNodeAndChildIds(action.payload);
      return {
        ...state,
        selectedOptions: state.selectedOptions.filter(
          (id) => !removeSelectionIds.includes(id),
        ),
      };
    }
    case ACTIONS.UPDATE_QUERY:
      return { ...state, query: action.payload };
    case ACTIONS.SYNC_SELECTED_OPTIONS:
      return { ...state, selectedOptions: action.payload.map(({ id }) => id) };
    case ACTIONS.SELECT_ALL: {
      const sectors = state.options;
      const subSectors = sectors.flatMap((option) => option.children);
      const industries = subSectors.flatMap((option) => option.children);
      const allOptions = [sectors, subSectors, industries]
        .flat()
        .map(({ id }) => id);
      return { ...state, selectedOptions: allOptions };
    }
    case ACTIONS.DESELECT_ALL: {
      return { ...state, selectedOptions: [] };
    }
    default:
      return state;
  }
};
