import React from 'react';

import css from './RegulationCard.module.css';
import ComplianceOutline from '../../../../icons/ComplianceOutline';

function CompliantButton({ isCompliant, onClick }) {
  const text = isCompliant ? 'Compliant' : 'Comply';
  return (
    <button
      type="button"
      className={css.button}
      data-compliant={isCompliant}
      onClick={onClick}
    >
      <ComplianceOutline />
      {text}
    </button>
  );
}

export default CompliantButton;
