import React, { useMemo } from 'react';

import css from './ToggleMore.module.css';

const Icon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10.2668L4 6.26683L4.93333 5.3335L8 8.40016L11.0667 5.3335L12 6.26683L8 10.2668Z"
      fill="#929AA3"
    />
  </svg>
);

function ToggleMore({ expanded, onClick }) {
  const text = useMemo(() => {
    if (expanded) return 'Show Less';

    return 'More';
  }, [expanded]);

  return (
    <button type="button" className={css.button} onClick={onClick}>
      {text}
      {Icon}
    </button>
  );
}

export default ToggleMore;
