export const buildCountryOptions = (countries, activeCountries) => {
  if (!countries) return [];

  const activeCountryIds = new Set(
    activeCountries.map((country) => country.id),
  );

  const sortedCountries = countries.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return sortedCountries.map((country) => ({
    ...country,
    selected: activeCountryIds.has(country.id),
  }));
};

export const filterCountriesByNameAndAltNames = (countries, query) => {
  if (!countries) return [];

  if (!query) return countries;

  return countries.filter((otp) => {
    const nameMatches = otp.name.toLowerCase().includes(query.toLowerCase());
    const altNamesMatches = otp.altNames?.some((altName) =>
      altName.toLowerCase().includes(query.toLowerCase()),
    );
    return nameMatches || altNamesMatches;
  });
};
