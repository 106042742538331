import { gql } from '@apollo/client';

const QUERY_SEARCH_ENTITIES_AND_CONDITIONAL_SUGGESTIONS = gql`
  query searchEntitiesAndConditionalSuggestions(
    $name: String
    $topics: [ID]
    $filterIndustries: [ID]
    $filterEntities: [ID]
    $filterActorTypes: [ActorTypes]
    $bookmarked: Boolean
    $connected: Boolean
    $compliance: Boolean
    $notes: Boolean
    $limit: Int
    $skip: Int
    $term: String
    $shouldRunAiSearch: Boolean!
  ) {
    searchEntities(
      name: $name
      topics: $topics
      filterIndustries: $filterIndustries
      filterEntities: $filterEntities
      filterActorTypes: $filterActorTypes
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
      compliance: $compliance
      limit: $limit
      skip: $skip
    ) {
      entities {
        id
        topics
        name
        shortestName
        inSubscription
        type
        subType
        image
        primaryRoleTitle
        primaryRoleEmployerName
        isConnected
      }
    }
    getSuggestions(term: $term) @include(if: $shouldRunAiSearch) {
      entities {
        id
        name
        image
        type
        inSubscription
        isConnected
        topics
        primaryRoleTitle
        primaryRoleEmployerName
      }
    }
  }
`;

export default QUERY_SEARCH_ENTITIES_AND_CONDITIONAL_SUGGESTIONS;
