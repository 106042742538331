import React from 'react';
import css from './Score.module.css';

import ScoreDown from '../../../icons/ScoreDown';
import ScoreUp from '../../../icons/ScoreUp';
import ScoreNeutral from '../../../icons/ScoreNeutral';

const ImpactType = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
};

const getImpactType = (score) => {
  if (score === 0) return ImpactType.Neutral;

  if (score > 0) return ImpactType.Positive;

  return ImpactType.Negative;
};

const getIconByScore = (score) => {
  if (score > 0) return <ScoreUp />;
  if (score < 0) return <ScoreDown />;
  return <ScoreNeutral />;
};

function Score({ score }) {
  if (typeof score !== 'number' || Number.isNaN(score)) return null;
  const text = score;
  const impact = getImpactType(score);
  const formatedScore = ` ${text}`;
  const icon = getIconByScore(score);

  return (
    <div className={css.main} data-impact={impact}>
      {formatedScore}{' '}
      <div className={css.icon} data-impact={impact}>
        {icon}
      </div>
    </div>
  );
}

export default Score;
