import React, { useMemo } from 'react';

import css from './EventCard.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import useNavigation from '../../../hooks/useNavigation';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import NotesOutline from '../../../../icons/NotesOutline';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import Score from '../../Score/Score';
import AxisUserGuard from '../../AxisUserGuard/AxisUserGuard';
import { calcImportanceScore } from '../../../shared/events';

function EventCard({ event }) {
  const router = useRouteInformation();

  const { toggleEventDossier } = useNavigation();

  const onAddNoteClick = () => {
    const data = {
      ...event,
      id: event.id,
      title: event.title,
      sources: event.sources,
      entities: event.entities,
      briefs: event.industries || event.briefs,
      countries: event.countries,
      dossierEntityId: router.query.get('entityId'),
    };
    router.openDossier({ event: data, dossierType: 'event' });
  };

  const score = useMemo(
    () => calcImportanceScore(event.magnitude, event.sentiment),
    [event],
  );

  return (
    <BaseCard.Root>
      <BaseCard.Row>
        <BaseCard.LeftColumn>
          <BaseCard.DateRow
            date={event.date}
            before={<Score score={score} />}
          />

          <BaseCard.Title
            className={css.title}
            onClick={() => toggleEventDossier(event.id)}
          >
            {event.title}
          </BaseCard.Title>

          <BaseCard.Summary summary={event.summary} bullets={event.bullets} />
        </BaseCard.LeftColumn>

        <BaseCard.RightColumn>
          <BaseCard.InfoColumn title="Actors">
            <div className={css.entities}>
              {event.entities.map((entity) => (
                <EntityPill
                  key={entity.id}
                  id={entity.id}
                  image={entity.image}
                  name={entity.name}
                  shortName={entity.shortestName}
                  tooltip={entity.tooltip}
                  type={entity.type}
                  isConnected={entity.isConnected}
                  inSubscription={entity.inSubscription}
                  autoOpenDossier
                  size="small"
                />
              ))}
            </div>
          </BaseCard.InfoColumn>

          {event.industries?.length > 0 && (
            <BaseCard.InfoColumn title="Industries">
              <BaseCard.BriefPillList
                isGeography={false}
                briefs={event.industries}
              />
            </BaseCard.InfoColumn>
          )}

          {event.countries?.length > 0 && (
            <BaseCard.InfoColumn title="Countries">
              <BaseCard.BriefPillList isGeography briefs={event.countries} />
            </BaseCard.InfoColumn>
          )}

          <AxisUserGuard>
            {!!event.language && event.language !== 'en' && (
              <BaseCard.InfoColumn title="Language">
                <div className={css.eventLanguage}>{event.language}</div>
              </BaseCard.InfoColumn>
            )}
          </AxisUserGuard>
        </BaseCard.RightColumn>
      </BaseCard.Row>
      <BaseCard.BottomRow className={css.footer}>
        <div className={css.buttons}>
          <button type="button" className={css.button} onClick={onAddNoteClick}>
            <NotesOutline />
            Add Note
            {event.notesCount > 0 && (
              <div className={css.count}>{event.notesCount}</div>
            )}
          </button>
          <div className={css.divider} />

          {event.quotes.length > 0 && (
            <button
              type="button"
              className={css.button}
              onClick={() => toggleEventDossier(event.id, true)}
            >
              Quotes <div className={css.count}>{event.quotes.length}</div>
            </button>
          )}
        </div>
        <BaseCard.Sources sources={event.sources} />
      </BaseCard.BottomRow>
    </BaseCard.Root>
  );
}

export default EventCard;
