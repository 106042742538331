import React from 'react';

import css from './NewLoadingState.module.css';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';

function NewLoadingState() {
  return (
    <>
      <div className={css.header}>
        <DossierHeader />
      </div>
      <div className={css.loadingState}>
        <div className={`skeleton-v2 ${css.avatar}`} />
        <div style={{ height: 60 }} className="skeleton-v2" />
        <div style={{ height: 25, width: 120 }} className="skeleton-v2" />
        <div style={{ height: 40 }} className="skeleton-v2" />

        <div style={{ height: 25, width: 110 }} className="skeleton-v2" />
        <div style={{ height: 40, width: 200 }} className="skeleton-v2" />
        <div style={{ height: 25, width: 180 }} className="skeleton-v2" />
        <div style={{ height: 40, width: 300 }} className="skeleton-v2" />
      </div>
    </>
  );
}

export default NewLoadingState;
