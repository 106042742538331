import { useQuery } from '@apollo/client';
import GET_APPOINTMENT_BY_ID from '../../queries/getAppointmentById';

function useAppointment(id) {
  const { data, loading } = useQuery(GET_APPOINTMENT_BY_ID, {
    variables: { appointmentId: id },
  });

  return { appointment: data?.getAppointmentById || null, loading };
}

export default useAppointment;
