import { useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { flattenTree } from '../../../../utils';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import autoCompleteQuery from '../../../../queries/searchAutocomplete';
import { filterCountriesByNameAndAltNames } from '../../Filters/CountryFilter/utils';

function useSuggestions() {
  const [suggestions, setSuggestions] = useState([]);
  const { briefs = [], subscribedCountries = [] } = useAxisData();
  const industries = useMemo(() => flattenTree(briefs), [briefs]);

  const [searchEntities, { loading }] = useLazyQuery(autoCompleteQuery, {
    onCompleted: (data) => {
      const entityList = data.autoCompleteQuery.entities;

      const newSuggestions = entityList.map((entity) => ({
        ...entity,
        type: entity.entityType,
        pillType: 'entity',
      }));

      setSuggestions(newSuggestions);
    },
  });

  const filterMatchingIndustries = (searchTerm = '') => {
    const query = searchTerm.substring(1);
    const filteredIndustries = industries.filter(({ name }) =>
      name.toLowerCase().startsWith(query.toLowerCase()),
    );

    const filteredCountries = filterCountriesByNameAndAltNames(
      subscribedCountries,
      query,
    );

    const countriesSuggestions = filteredCountries.map((country) => ({
      id: country.id,
      name: country.name,
      pillType: 'country',
    }));

    const industriesSuggestions = filteredIndustries.map((industry) => ({
      id: industry.id,
      name: industry.name,
      pillType: 'industry',
    }));

    setSuggestions(
      [...industriesSuggestions, ...countriesSuggestions].slice(0, 6),
    );
  };

  const runSuggestions = (searchTerm = '') => {
    if (searchTerm === '') {
      setSuggestions([]);
      return;
    }

    const isIndustries = searchTerm.startsWith('#');

    if (isIndustries) {
      filterMatchingIndustries(searchTerm);
    } else {
      searchEntities({ variables: { term: searchTerm, limit: 6 } });
    }
  };

  return { runSuggestions, isLoading: loading, suggestions };
}

export default useSuggestions;
